


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-input-number__decrease {
    .el-icon-arrow-down:before {
        content: "\e6df" !important;
    }
}
